import React from 'react';

function AboutPage() {
  return (
    <>
      <h1>
        Turn on your creativity!
      </h1>
    </>
  );
}

export default AboutPage;